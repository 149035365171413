
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
/* eslint-disable react/jsx-props-no-spreading */
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import '@fortawesome/fontawesome-svg-core/styles.css';
import theme from 'theme/theme';
import { gtagWebVitalsEvent, pageview } from 'utils/analytics/gtag';
import { sendQuickMetricAnalytics } from 'utils/analytics/quickmetricWebVitals';
import { initializeStore, useStore } from 'store/store';
import LinkedInPixel from 'components/analytics/LinkedInPixel';
import { FacebookPixel } from 'components/analytics/facebook-pixel';
import { SWRConfigProvider } from 'components/swr-config-provider';
import i18n from '../i18n.json';

Router.events.on('routeChangeComplete', (url) => pageview(url));

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const store = useStore(pageProps.initialReduxState);

  useEffect(() => {
    if ('serviceWorker' in navigator)
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(
          (registration) => {
            // eslint-disable-next-line no-console
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope,
            );
          },
          (err) => {
            // eslint-disable-next-line no-console
            console.log('Service Worker registration failed: ', err);
          },
        );
      });
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <SWRConfigProvider>
          <FacebookPixel />
          <LinkedInPixel />
          <NextNProgress
            color="#150090"
            stopDelayMs={200}
            height={5}
            showOnShallow
          />
          <Head>
            {i18n.locales.map((loc) => (
              <link
                key={loc}
                rel="alternate"
                hrefLang={loc}
                href={`/${loc}${asPath}`}
              />
            ))}
          </Head>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <Component {...pageProps} />
            </StyledEngineProvider>
          </ThemeProvider>
        </SWRConfigProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export function reportWebVitals(metric) {
  if (process.env.NEXT_PUBLIC_COLLECT_WEB_VITALS === 'true') {
    gtagWebVitalsEvent(metric);
    sendQuickMetricAnalytics(metric);
  }
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  // initialise redux store on server side
  const reduxStore = initializeStore({});

  appProps.pageProps = {
    ...appProps.pageProps,
    initialReduxState: reduxStore.getState(),
  };

  return appProps;
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  