import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axios } from '@nploy/shared';
import { ICandidateSelectedCityDto } from '@nploy/ui-infrastructure';

export const PLACES_API = 'places-api';

export const placesApi = createApi({
  reducerPath: PLACES_API,
  tagTypes: [PLACES_API],
  baseQuery: async (args) => {
    const { data } = await axios.request(args);
    return {
      data,
    };
  },
  endpoints: (build) => ({
    getCities: build.query<ICandidateSelectedCityDto[], void>({
      query: () => ({
        url: 'list/cities',
        method: 'GET',
        params: {
          all: 1,
        },
      }),
      providesTags: [PLACES_API],
      transformResponse: (cities: ICandidateSelectedCityDto[]) =>
        [...(cities || [])].sort((firstCity, secondCity) =>
          firstCity.name > secondCity.name ? 1 : -1,
        ),
    }),
  }),
});

export const { useGetCitiesQuery } = placesApi;
